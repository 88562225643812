import React, {useContext} from 'react'
import {Box, Grid, Flex, Text} from '@chakra-ui/react'
import Transition from 'components/layout/Transition'
import {ConfigContext} from '../layouts'
import {graphql} from 'gatsby'

function onlyUnique(value, index, self) {
  return self.indexOf(value) === index
}

const Debug = ({data}) => {
  const config = useContext(ConfigContext)
  const sanityAttributes = data.allSanityAttribute.nodes.map((a) => a)
  const allTags = []
  for (const item of data.allSanityProduct.nodes) {
    for (const tag of item.store.tags.split(', ')) {
      if (tag && tag.includes(':')) {
        allTags.push(tag)
      }
    }
  }
  const uniqueTags = allTags.filter(onlyUnique)
  const attributes = uniqueTags.map(tag => {
    if (tag && tag.includes(':')) {
      const attribute = tag.split(':')
      return attribute[0]
    }
  })
  const uniqueShopifyTags = attributes.filter(onlyUnique)

  const renderOption = (option) =>
    <Box sx={{ p: '1.5rem' }}>
      <div>{option.title}</div>
      <Flex sx={{
        width: '40rem',
        flexDirection: 'row',
        alignContent: 'space-between',
        justifyContent: 'flex-start',
        flexWrap: 'nowrap',
        'div': { pr: '1rem' }
      }}>
        <div>{option.active ? '☑ Active' : '☐ Active'}</div>
        <div>{option.key ? '☑ Key' : '☐ Key'}</div>
        <div>{option.image ? 'Images uploaded (1/1)' : 'Images uploaded (0/1)'}</div>
      </Flex>
    </Box>


  return (
    <Transition>
      <Box
        sx={{
          width: '100%',
          height: '100%',
          overflow: 'auto'
        }}
      >
        <table>
          <thead>
            <tr>
              <th>Attributes</th>
            </tr>
          </thead>
          <tbody>
          {config.attributes.map(attribute => (
            <tr key={attribute.namedTag}>
              <Box
                as='th'
                sx={{
                  position: 'sticky',
                  left: 0,
                  width: '20rem',
                  boxShadow: `inset -1px 0px 0 black`
                }}
              >{attribute.title}</Box>
              <td>
                <Box sx={{ width: '30rem', p: '1.5rem' }}>
                  <div>{attribute.active ? '☑ Active' : '☐ Active'}</div>
                  {attribute.filteringAndSearch ? (
                    <React.Fragment>
                      <div>☑ Filtering & search</div>
                      <div>{`${attribute.filteringAndSearch?.prefix} "${attribute.filteringAndSearch?.defaultText}"`}</div>
                    </React.Fragment>
                  ) : <div>☐ Filtering & search</div>}
                </Box>

              </td>
              { attribute.groups && attribute.groups.length > 0 && (
              <td>
                <tr>
                  <Box as='th'>Groups</Box>
                  <Box as='th' sx={{ left: '460px'}}>Options</Box>
                  {/*<th colSpan={attribute.groups.length}>Options</th>*/}
                </tr>
                { attribute.groups && attribute.groups.length > 0 &&
                    attribute.groups.map(group => (
                      <tr key={group.title}>
                        <Box as='td' bg='white' p='1.5rem' sx={{
                          width: '20rem', position: 'sticky', left: '0',
                          // boxShadow: 'inset 0px -1px 0 black'
                         }}>
                          <Text variant='caps'>{group.title}</Text>
                        </Box>
                        {group.options.map(option =>
                          <td key={option.title}>
                            {renderOption(option)}
                          </td>
                        )}
                      </tr>
                    ))
                }
              </td>
              )}
                { attribute.options && attribute.options.length > 0 && (
                  <td>
                    <tr>
                      <th>Options</th>
                    </tr>
                    <tr>
                      {attribute.options.map(option =>
                        <td key={option.title}>
                          {renderOption(option)}
                        </td>
                      )}
                    </tr>
                  </td>
                )}
            </tr>
          ))}
          </tbody>
        </table>
      </Box>
      <Grid gap={'1rem'} templateColumns={'2rem'} p='1.5rem'>
        <Box>
          {`Shopify "Named Tags"`}:<br/>
          [<Text sx={{color: 'red', fontSize: '18px'}}>Red text</Text>] Missing in Sanity Studio
          {uniqueTags.sort((a, b) => {
            const attributeA = a.split(':')
            const attributeB = b.split(':')
            return attributeA[0].localeCompare(attributeB[0])
          }).map(a => {
            let exists = sanityAttributes.find(o => o.namedTag === a.split(':')[0])
            if (exists && data.allSanityAttributeOption) {
              exists = data.allSanityAttributeOption.nodes.find(o => a.split(':')[1] === o.title)
            }
            const color = exists ? 'inherit' : 'red'
            const count = data.allSanityProduct.nodes.filter(x => x.store.tags.includes(a)).length
            return <div key={a} style={{color: color}}>{a} ({count})</div>
          })}
        </Box>
        <Box>
          {`Shopify Attribute "Keys"`}:<br/>
          [<Text sx={{color: 'red', fontSize: '18px'}}>Red text</Text>] Missing in Sanity Studio
          {uniqueShopifyTags.map(a => {
            const exists = sanityAttributes.find(o => o.namedTag === a.split(':')[0])
            return <div key={a} style={{color: exists ? 'inherit' : 'red'}}>
              {a}
            </div>
          })}
        </Box>
        <Box>
          Sanity Attributes:
          {config.attributes.map(o => (<div key={o.namedTag}>
            <div>{o.namedTag}</div>
          </div>))}
        </Box>
      </Grid>
    </Transition>
  )
}

export default Debug

export const query = graphql`
query
{
  allSanityAttribute
  {
    nodes
    {
      title
      namedTag
    }
  }
  allSanityAttributeOption
  {
    nodes
    {
      key
      title
    }
  }
  allSanityProduct
  {
    nodes
    {
      store
      {
        tags
      }
      attributes
      {
        key
        value
      }
    }
  }
}
`
